@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app,
.content {
  height: "100vh"; /* can affect background of objects in app, remove for white background */
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e6e6e6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a4a4a4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/*
.select-wrapper {
  position: relative;
  margin-bottom: -15px;
} */
/*
.select-wrapper label {
  position: absolute;
  top: -1.5rem;
  left: 0;
  font-weight: bold;
  font-size: 1.1rem;
  color: #333;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
} */
/*
.select-wrapper select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  appearance: none;
} */

.viewButton {
  margin-top: 15px;
  margin-left: 15px;
  box-shadow: #333;
  padding: 15px;
  cursor: pointer;
  font-family: Arial;
  font-size: 15px;
  width: 20;
  height: auto;
  padding: 0.4rem;
  padding-left: 0.5rem;
  border: 2px solid black;
  border-radius: 0.25rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: row;
  transition: background-color 1s ease-in-out;
  background-color: #f2f0f0;
  outline: none;
}

.viewButton.active {
  background-color: rgba(199, 214, 122, 0.85);
}

.viewButton:hover {
  background-color: #ccdb77;
  animation-name: jiggle;
  animation-duration: 0.25s;
  animation-iteration-count: 1;
}

.pdfButton {
  margin-top: 15px;
  margin-right: 30px;
  box-shadow: #333;
  cursor: pointer;
  font-family: Arial;
  font-size: 15px;
  width: 20;
  height: auto;
  border: 2px solid black;
  border-radius: 0.25rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: row;
  transition: background-color 1s ease-in-out;
  margin-left: auto;
}

.pdfButton:hover {
  background-color: #ccdb77;
  animation-name: jiggle;
  animation-duration: 0.25s;
  animation-iteration-count: 1;
}

.icon {
  width: 28px;
  height: 28px;
  margin-left: 7px;
  align-items: center;
}

.row1-box {
  background-color: white;
  color: white;
}

.link {
  text-decoration: none;
}

.container {
  position: relative;
  text-align: left;
  color: black;
}

.landing-title {
  position: absolute;
  top: 30px;
  left: 55px;
  width: 300px;
}

.landing-logo {
  position: absolute;
  top: 35vh;
  left: 43vw;
  width: 180px;
  height: 75px;
}

.erase {
  visibility: hidden;
}

.landing-topbar-logo {
  margin-left: 35px;
  margin-top: 3px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.5%;
  margin-right: -3%;
  padding-bottom: 6px;
}

.buttons-right {
  display: flex;
}
.buttons-left {
  display: flex;
}

.buttons-left button {
  margin-left: 10px;
}

.buttons-right button {
  margin-left: 10px;
}

/* Landing buttons v2 */
.button-container-landing {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 80vh;
}

.button-array {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.array-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: white;
  color: #222222;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 20vw;
}

.array-button:hover:not(.disabled) {
  background-color: #ccdb77;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.icon {
  margin-left: 1rem;
}

.loading {
  cursor: progress;
}

.toaster-slot.top-right div {
  max-width: 500px; /* Adjust as needed */
}

/*****************************************************************/
/************************JIGGLE ANIMATION*************************/
/*****************************************************************/
@keyframes jiggle {
  0% {
    transform: rotateZ(0deg);
  }
  30% {
    transform: rotateZ(0.5deg);
  }
  70% {
    transform: rotateZ(-0.5deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

/*****************************************************************/
/******************** FOR PRINTING PDF ***************************/
/*****************************************************************/

.print-wrapper {
  width: 100%;
  height: 100%;
}

/*****************************************************************/
/******************* FOR PDF BUTTON FLICKER***********************/
/*****************************************************************/

@keyframes flashGreen {
  0% { background-color: initial; }
  50% { background-color: rgba(166,202,97,0.5); }
  100% { background-color: initial; }
}

/*****************************************************************/
/*************** FOR ENTREPRISES TABLE ***************************/
/*****************************************************************/
