.indications {
  animation: fadeInOut 8.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInOut {
  0% {opacity: 0;}
  30% {opacity: 0;}
  40% {opacity: 1;}
  90% {opacity: 1;}
  100% {opacity: 0;}
}
