/* POUR LES POPUPS: */
/* Ces paramètres CSS servent à reconfigurer certaines propriétés par défaut mapbox-gl */
.mapboxgl-popup-content {
    backdrop-filter: blur(5px) !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 3px !important;
    justify-content: center !important;
    margin: 0 auto !important;
    background-color: rgba(255, 255, 255, 0.75) !important;
    text-align: center !important;
    font-size: 11px;
}

.mapboxgl-popup-content>p {
    color: rgba(8, 89, 147, 0.9);
    font-weight: 450;
    font-size: 14px;
    text-shadow: 1px 1px 1px #ffffff;
}

.mapboxgl-popup-tip {
    border-bottom-color: rgba(240, 240, 240, 0.8) !important;
}

.hidden {
    display: none !important;
}

.mapboxgl-popup {
    opacity: 0;
}

.popup-text {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.7px;
    min-width: 70px;
    text-shadow: 2px 2px 2px #ffffff;
}

.darkmode-popup {
    color: black;
}

.popup-line-seperator {
    background-color: black;
    width: 90%;
    margin: 5px auto;
    border: none;
    height: 0.5px;
}


.popup {
    /* popup-graph */
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.popup-job-fade {
    transition: opacity 0.4s;
    opacity: 0.85;
}

.popup-graph-fade {
    transition: opacity 0.1s, height 0.6s !important;
    opacity: 1 !important;
    height: 240px;
}

@keyframes dot {

    0%,
    20% {
        opacity: 0;
    }

    30%,
    100% {
        opacity: 1;
    }
}